// extracted by mini-css-extract-plugin
export var imageContainer = "styles-module--imageContainer--NlLEd";
export var img = "styles-module--img--SI-5M";
export var link = "styles-module--link--RFUEL";
export var links = "styles-module--links--rQUFZ";
export var member = "styles-module--member--2IeD5";
export var name = "styles-module--name--gMgaD";
export var overlay = "styles-module--overlay--qTpgv";
export var teamIntro = "styles-module--teamIntro--bWLdl";
export var teamMembers = "styles-module--teamMembers--n8xkV";
export var text = "styles-module--text--9gCzS";
export var theTeamWrapper = "styles-module--theTeamWrapper--sgveD";
export var title = "styles-module--title--nLl1E";