import React from 'react'
import classnames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

const TheTeam = () => (
  <StaticQuery
    query={query}
    render={data => {
      const teamMembers = [
        {
          name: 'Ben Riewe',
          text: `Ben is an eight-year gaming industry veteran.
          He used to work for European's biggest AAA free to play publishers and was responsible for
          monetization & product strategies. In 2014 Ben joined Dojo Madness as one of the founding
          employees were he currently acts as CEO of Shadow.`,
          img: data.bio_ben.childImageSharp.gatsbyImageData,
          links: [
            {
              label: 'linkedin',
              href: 'https://de.linkedin.com/in/benjamin-riewe-69062755',
            },
          ],
        },
        {
          name: 'Roman Almeida',
          text: `Roman has started his first venture backed gaming studio at 19 and after a decade
          long hiatus he has rejoined the startup game in 2016 at Dojo Madness. He is leading the
          tech team at Shadow since 2019, trying to bring a pragmatic perspective to the technical
          challenges his team faces while trying to deliver the biggest impact to our customers.`,
          img: data.bio_roman.childImageSharp.gatsbyImageData,
          links: [],
        },
      ]

      return (
        <section className={classnames(styles.theTeamWrapper, 'col-centered')}>
          <div className={styles.teamIntro}>
            <h4>Behold, the reason of our success</h4>
            <h2>Our Staff and Leadership</h2>
            <p>
              The Shadow team includes 18 staff dedicated to product design,
              development, testing, security, and client support. We are rooted
              in the DOJO Madness offices in Berlin, Germany, while some of the
              team dials in from other parts of Europe or North America.
            </p>
          </div>
          <div className={styles.teamMembers}>
            {teamMembers.map((member, index) => (
              <div className={styles.member} key={index}>
                <div className={styles.imageContainer}>
                  <GatsbyImage image={member.img} className={styles.img} />
                  <div className={styles.overlay}>
                    <div className={styles.title}>{member.title}</div>
                  </div>
                </div>
                <p className={styles.text}>
                  <h3 className={styles.name}>{member.name}</h3>
                  {member.text}
                </p>
                {'links' in member && (
                  <div className={styles.links}>
                    {member.links.map((link, linkIndex) => (
                      <a
                        className={styles.link}
                        key={link.label}
                        href={link.href}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {link.label}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      )
    }}
  />
)

const query = graphql`
  {
    bio_ben: file(relativePath: { eq: "bio-ben.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 318, layout: CONSTRAINED)
      }
    }
    bio_roman: file(relativePath: { eq: "bio-roman.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 318, layout: CONSTRAINED)
      }
    }
  }
`

export default TheTeam
