import React from 'react'
import * as styles from './styles.module.scss'
import LOL from '../../../images/svg/lol-icon.svg'
import CS from '../../../images/svg/cs-icon.svg'
import Dota from '../../../images/svg/dota-icon.svg'

const sidebarItems = [
  {
    statValue: '3',
    subtitle: 'Games supported',
    icons: [LOL, CS, Dota],
  },
  {
    statValue: '25+',
    subtitle: 'Shadow-powered clients',
  },
  {
    statValue: '18',
    subtitle: 'Gamers/Geeks on staff',
  },
]

const OurStory = () => (
  <section className={styles.ourStoryWrapper}>
    <div className={styles.content}>
      <div className={styles.main}>
        <h4>The short version of</h4>
        <h2>Our Story</h2>
        <p>
          Founded in 2016, Shadow is the first dedicated, multi-title esports
          analytics solution. Supporting Counter-Strike: Global Offensive at
          launch, we soon added products for League of Legends and Dota 2, and
          our global list of clients at the top tier of international
          competition continues to grow.
        </p>
        <p>
          Every piece of data Shadow collects and every metric, tool, and
          visualization we build is informed not only by our direct experiences
          with esports team ownership, coaching, and competition, but also by
          insights gathered in close partnership with the championship-winning
          coaches, analysts, and players who use Shadow every day. While we draw
          inspiration from proven practices within traditional sports analytics,
          we’re keenly aware of what makes each esports title unique—from data
          collection to match preparation techniques to performance measurement.
          We use this wide range of perspectives to create innovative, custom
          solutions that contribute to the only outcome that really matters:
          better in-game performances.
        </p>
        <p>
          Shadow is a product of <strong>Shadow Esports</strong>, a Berlin-based
          esports startup that harnesses the power of big data to build tools
          and services that help players and fans to understand and master their
          favorite esports games. We are backed by <strong>BITKRAFT</strong>, an
          early stage investor and company builder focussed entirely on the
          esports sector.
        </p>
      </div>
      <div className={styles.sidebar}>
        {sidebarItems.map((i, index) => (
          <div className={styles.stat} key={i.subtitle}>
            {'statValue' in i && (
              <p className={styles.statValue}>{i.statValue}</p>
            )}
            <div>
              {'subtitle' in i && (
                <p className={styles.subtitle}>{i.subtitle}</p>
              )}
              {'icons' in i && (
                <div className={styles.icons}>
                  {i.icons.map((Icon, iconIndex) => (
                    <Icon key={i.subtitle + 'icon' + iconIndex} />
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default OurStory
