import React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/about/AboutUsHero'
import OurStory from '../components/about/OurStory'
import TheTeam from '../components/about/TheTeam'
import Partnerships from '../components/about/Partnerships'
import CookieNotice from '../components/shared/CookieNotice'

const About = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()
  return (
    <Layout>
      <SEO
        title='About Shadow'
        description='First dedicated, multi-title esports analytics solution. We are the esports analytics professionals - The Shadow team includes experts dedicated to product design, development, testing, security, and client support.'
        keywords={[
          `shadow`,
          `esports`,
          `analytics`,
          `esports teams`,
          `teams`,
          `professional`,
          `csgo`,
          `cs:go`,
          `dota2`,
          `dota`,
          `dota 2`,
          `lol`,
          `league of legends`,
        ]}
      />
      <CookieNotice />
      <h1>About | Game-changing esports analytics - Shadow</h1>
      <Hero />
      <OurStory />
      <TheTeam />
      <Partnerships />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About)
