import React from 'react'
import classnames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundImg from '../../shared/BackgroundImg'
import * as styles from './styles.module.scss'

const Hero = () => (
  <section className={styles.heroWrapper}>
    <div className={classnames(styles.hero, 'col-centered')}>
      <h2 className={styles.text}>
        We are the{' '}
        <span className='text-color--primary'>esports analytics experts</span>
      </h2>
    </div>
    <StaticQuery
      query={graphql`
        {
          placeholderImage: file(
            relativePath: { eq: "img_about_team@2x.png" }
          ) {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => (
        <BackgroundImg
          image={data.placeholderImage.childImageSharp.gatsbyImageData}
        />
      )}
    />
  </section>
)

export default Hero
